import React from 'react'

import './main.scss'
import '../../../static/styles/globalStyles.scss'

import news from '../../../static/images/news/news.jpeg'

function LatestNews() {
    return (
        <div className="latestNews card">
           <h1> | Latest News</h1>
        <ul>
            <li className="newsCard ">
                <h1>Weekly download news</h1> 
                <div className="row">
                <img className="icon" src={news} alt="" /> 
                <p className="smallText">You can download new games, applications and other content from Nintendo eShop on your Nintendo Switch, and also via this website*. </p>
                </div>
            </li>
            <li className="newsCard">
                <h1>Weekly download news</h1> 
                <div className="row">
                <img className="icon" src={news} alt="" /> 
                <p className="smallText">You can download new games, applications and other content from Nintendo eShop on your Nintendo Switch, and also via this website*. </p>
                </div>
            </li>
            <li className="newsCard">
                <h1>Weekly download news</h1> 
                <div className="row">
                <img className="icon" src={news} alt="" /> 
                <p className="smallText">You can download new games, applications and other content from Nintendo eShop on your Nintendo Switch, and also via this website*. </p>
                </div>
            </li>
            <li className="newsCard">
                <h1>Weekly download news</h1> 
                <div className="row">
                <img className="icon" src={news} alt="" /> 
                <p className="smallText">You can download new games, applications and other content from Nintendo eShop on your Nintendo Switch, and also via this website*. </p>
                </div>
            </li>
            <li className="newsCard">
                <h1>Weekly download news</h1> 
                <div className="row">
                <img className="icon" src={news} alt="" /> 
                <p className="smallText">You can download new games, applications and other content from Nintendo eShop on your Nintendo Switch, and also via this website*. </p>
                </div>
            </li>
            <li className="newsCard">
                <h1>Weekly download news</h1> 
                <div className="row">
                <img className="icon" src={news} alt="" /> 
                <p className="smallText">You can download new games, applications and other content from Nintendo eShop on your Nintendo Switch, and also via this website*. </p>
                </div>
            </li>
            <li className="newsCard">
                <h1>Weekly download news</h1> 
                <div className="row">
                <img className="icon" src={news} alt="" /> 
                <p className="smallText">You can download new games, applications and other content from Nintendo eShop on your Nintendo Switch, and also via this website*. </p>
                </div>
            </li>
            <li className="newsCard">
                <h1>Weekly download news</h1> 
                <div className="row">
                <img className="icon" src={news} alt="" /> 
                <p className="smallText">You can download new games, applications and other content from Nintendo eShop on your Nintendo Switch, and also via this website*. </p>
                </div>
            </li>
            <li className="newsCard">
                <h1>Weekly download news</h1> 
                <div className="row">
                <img className="icon" src={news} alt="" /> 
                <p className="smallText">You can download new games, applications and other content from Nintendo eShop on your Nintendo Switch, and also via this website*. </p>
                </div>
            </li>
            <li className="newsCard">
                <h1>Weekly download news</h1> 
                <div className="row">
                <img className="icon" src={news} alt="" /> 
                <p className="smallText">You can download new games, applications and other content from Nintendo eShop on your Nintendo Switch, and also via this website*. </p>
                </div>
            </li>
            <li className="newsCard">
                <h1>Weekly download news</h1> 
                <div className="row">
                <img className="icon" src={news} alt="" /> 
                <p className="smallText">You can download new games, applications and other content from Nintendo eShop on your Nintendo Switch, and also via this website*. </p>
                </div>
            </li>
            <li className="newsCard">
                <h1>Weekly download news</h1> 
                <div className="row">
                <img className="icon" src={news} alt="" /> 
                <p className="smallText">You can download new games, applications and other content from Nintendo eShop on your Nintendo Switch, and also via this website*. </p>
                </div>
            </li>
            <li className="newsCard">
                <h1>Weekly download news</h1> 
                <div className="row">
                <img className="icon" src={news} alt="" /> 
                <p className="smallText">You can download new games, applications and other content from Nintendo eShop on your Nintendo Switch, and also via this website*. </p>
                </div>
            </li>
            <li className="newsCard">
                <h1>Weekly download news</h1> 
                <div className="row">
                <img className="icon" src={news} alt="" /> 
                <p className="smallText">You can download new games, applications and other content from Nintendo eShop on your Nintendo Switch, and also via this website*. </p>
                </div>
            </li>
            <li className="newsCard">
                <h1>Weekly download news</h1> 
                <div className="row">
                <img className="icon" src={news} alt="" /> 
                <p className="smallText">You can download new games, applications and other content from Nintendo eShop on your Nintendo Switch, and also via this website*. </p>
                </div>
            </li>
            <li className="newsCard">
                <h1>Weekly download news</h1> 
                <div className="row">
                <img className="icon" src={news} alt="" /> 
                <p className="smallText">You can download new games, applications and other content from Nintendo eShop on your Nintendo Switch, and also via this website*. </p>
                </div>
            </li>
            <li className="newsCard">
                <h1>Weekly download news</h1> 
                <div className="row">
                <img className="icon" src={news} alt="" /> 
                <p className="smallText">You can download new games, applications and other content from Nintendo eShop on your Nintendo Switch, and also via this website*. </p>
                </div>
            </li>
            <li className="newsCard">
                <h1>Weekly download news</h1> 
                <div className="row">
                <img className="icon" src={news} alt="" /> 
                <p className="smallText">You can download new games, applications and other content from Nintendo eShop on your Nintendo Switch, and also via this website*. </p>
                </div>
            </li>
            <li className="newsCard">
                <h1>Weekly download news</h1> 
                <div className="row">
                <img className="icon" src={news} alt="" /> 
                <p className="smallText">You can download new games, applications and other content from Nintendo eShop on your Nintendo Switch, and also via this website*. </p>
                </div>
            </li>
            <li className="newsCard">
                <h1>Weekly download news</h1> 
                <div className="row">
                <img className="icon" src={news} alt="" /> 
                <p className="smallText">You can download new games, applications and other content from Nintendo eShop on your Nintendo Switch, and also via this website*. </p>
                </div>
            </li>
            <li className="newsCard">
                <h1>Weekly download news</h1> 
                <div className="row">
                <img className="icon" src={news} alt="" /> 
                <p className="smallText">You can download new games, applications and other content from Nintendo eShop on your Nintendo Switch, and also via this website*. </p>
                </div>
            </li>
        </ul>
        </div>
    )
}

export default LatestNews
